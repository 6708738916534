module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"XPIM","short_name":"XPIM","start_url":"/","background_color":"#fff","theme_color":"#000","display":"standalone","icon":"./src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fc97ff2849ab58ff830a8af093d9032e"},
    },{
      plugin: require('../node_modules/@devular/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"xpim.de","proxyScript":"/js/script.js","proxyApi":"/api/event"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
