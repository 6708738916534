module.exports = {
  metadata: {
    title: "XPIM",
    description: "Website created with create-tpws-website",
    author: "https://pulso-media.de",
    generator: "turingpages",
  },

  tpws: {
    environments: {
      development: {
        siteUrl: "http://localhost:9000",
      },
      staging: {
        siteUrl: "https://preview.staging.xpimde.pulso-media.de",
      },
      production: {
        siteUrl: "https://xpim.de",
      },
    },
    defaultLocale: "de",
    dynamicTemplate: "./src/templates/dynamic-template.js",
    subfont: false,
    allowHtml: false,
  },

  strapi: {
    environments: {
      development: {
        url: "http://localhost:3000",
      },
      staging: {
        url: "https://staging.xpimde.pulso-media.de",
        token:
          "c1089d596330bd95c771e6b7fc0b0e9832cc06b72a16448764847e7bcbc0e34aa8739d3c748ee3ac247af9c295cd729f6b04752ff71b961b88be17f4d3f052c9b9e391115c9a4b11573fb944c48c41c7a239218154f3a84f0a99a32330586c2e2a3284f6975ee792852265a304194587c5bbf86439b4958de0c58a66adbd9829",
      },
      production: {
        url: "https://xpimde.pulso-media.de",
        token:
          "c1089d596330bd95c771e6b7fc0b0e9832cc06b72a16448764847e7bcbc0e34aa8739d3c748ee3ac247af9c295cd729f6b04752ff71b961b88be17f4d3f052c9b9e391115c9a4b11573fb944c48c41c7a239218154f3a84f0a99a32330586c2e2a3284f6975ee792852265a304194587c5bbf86439b4958de0c58a66adbd9829",
      },
    },
    collectionTypes: [],
    singleTypes: [],
  },
  /* shopify: {
    url: "mystore.myshopify.com",
    password: "<<shppa_pwd>>",
    token: "<<token>>",
  }, */
};
